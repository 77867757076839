import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FormSchemaType } from '..';

import { FieldSchemaType } from './form-switch-staff-schema';

/**
 * Parses a "required_if" validation string and extracts the relevant field and values.
 * @param validation - The validation string to parse.
 * @returns An object containing the fieldName and cleanedValues or null if the validation string is not a "required_if".
 */
export const parseRequiredIf = (validation: string | undefined) => {
    if (!validation?.startsWith('required_if:')) return null;
    const [, rest] = validation.split(':');
    const [fieldName, ...values] = rest?.split(',') ?? [];
    const cleanedValues = values.map((value) => value.replace(/"/g, ''));
    return { fieldName, cleanedValues };
};

/**
 * Checks if a field should be hidden based on "required_if" validation logic.
 * @param field - The field schema to check.
 * @param formValues - The current form values.
 * @returns True if the field should be hidden, false otherwise.
 */
const checkIfHidden = (field: FieldSchemaType, formValues: FormSchemaType) => {
    const requiredIf = field.validation?.find((v) => v.startsWith('required_if:'));
    const parsed = parseRequiredIf(requiredIf);
    if (!parsed) return false;

    // Find the field to check in the form values
    const fieldToCheck = formValues.fields?.find((f) => f.key === parsed.fieldName);
    if (!fieldToCheck) return false;

    // Get the value of the field to check
    const fieldToCheckValue = fieldToCheck.value;

    // Sometimes the fieldToCheck can be an array, so we need to check if the value is included in the array
    if (Array.isArray(fieldToCheckValue)) {
        return (
            fieldToCheckValue.length === 0 ||
            !fieldToCheckValue.some((v) => parsed.cleanedValues.includes(v))
        );
    } else if (typeof fieldToCheckValue === 'string') {
        return !parsed.cleanedValues.includes(fieldToCheckValue);
    }

    return false;
};

/**
 * Custom hook to manage fields that should be conditionally hidden based on form values.
 * @param params - Parameters for the hook.
 * @param params.form - The form object returned by useForm.
 * @param params.fields - The fields schema array.
 * @returns The filtered fields that are not hidden.
 */
const useRequiredIf = ({
    form,
    fields,
}: {
    form: ReturnType<typeof useForm<FormSchemaType>>;
    fields: FieldSchemaType[];
}) => {
    // State to keep track of hidden fields' keys
    const [hiddenFields, setHiddenFields] = useState<Set<string>>(new Set());

    // Callback to update the hidden fields based on current form values
    const updateHiddenFields = useCallback(
        (formValues: FormSchemaType) => {
            const newHiddenFields = new Set<string>();
            fields.forEach((field) => {
                if (checkIfHidden(field, formValues)) {
                    newHiddenFields.add(field.key);
                }
            });
            setHiddenFields(newHiddenFields);
        },
        [fields],
    );

    // Effect to subscribe to form value changes and update hidden fields accordingly
    useEffect(() => {
        const subscription = form.watch((formValues) => {
            const filteredFormValues = isFormSchemaType(formValues) ? formValues : null;

            // Return true if the value matches the FormSchemaType structure, false otherwise
            function isFormSchemaType(value: unknown): value is FormSchemaType {
                return typeof value === 'object' && value !== null && 'fields' in value;
            }

            if (filteredFormValues) {
                updateHiddenFields(filteredFormValues);
            }
        });

        // Cleanup subscription on unmount
        return () => subscription.unsubscribe();
    }, [form, updateHiddenFields]);

    // Effect to update hidden fields initially based on current form values
    useEffect(() => {
        updateHiddenFields(form.getValues());
    }, [updateHiddenFields, form]);

    return {
        hiddenFields,
    };
};

export { useRequiredIf };
