import { Label, Text } from '@i2e/components';

import CheckboxField from '../checkbox-field';
import CheckboxesField from '../checkboxes-field';
import CountriesField from '../countries-field';
import DatePickerField from '../date-picker-field';
import DropdownField from '../dropdown-field';
import InputField from '../input-field';
import RadioButtonsField from '../radio-buttons-field';
import TextareaField from '../textarea-field';

import { FieldSchemaType } from './form-switch-staff-schema';
import { checkRequired, factoryDisabledDates } from './helpers';

export const FormSwitchStaff = ({
    prefix,
    fields,
    hiddenFields,
    customOptions,
}: {
    prefix?: string;
    fields: FieldSchemaType[];
    hiddenFields: Set<string> | undefined;
    customOptions?: Record<string, unknown[]>;
}) => {
    return (
        <>
            {fields.map((item, index) => {
                // Check if required is in the validation array
                const required = checkRequired(item);

                if (hiddenFields?.has(item.key)) {
                    return null;
                }

                const name = prefix ? `${prefix}.fields.${index}.value` : `fields.${index}.value`;

                // Add a switch case to load the correct component based on the type of field
                switch (item.type) {
                    case 'description':
                        return (
                            <div className="flex flex-col space-y-2" key={item.key}>
                                <Label>{item.label}</Label>
                                <Text>{item.description}</Text>
                            </div>
                        );
                    case 'text':
                        return (
                            <InputField rules={{ required }} {...item} key={item.key} name={name} />
                        );
                    case 'textarea':
                        return (
                            <TextareaField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={name}
                            />
                        );
                    case 'checkbox':
                        return (
                            <CheckboxField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={name}
                            />
                        );
                    case 'radiobuttons':
                        return (
                            <RadioButtonsField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={name}
                            />
                        );
                    case 'datepicker':
                        return (
                            <DatePickerField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={name}
                                disabledDates={factoryDisabledDates(item.validation)}
                            />
                        );
                    case 'checkboxes':
                        return (
                            <CheckboxesField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={name}
                            />
                        );
                    case 'dropdown':
                        return (
                            <DropdownField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={name}
                            />
                        );
                    case 'countries':
                        return (
                            <CountriesField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={name}
                                countries={customOptions?.countries}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </>
    );
};
